:root {
    --ai-blue-900: hsl(218, 100%, 17%);
    --ai-blue-800: hsl(216, 98%, 25%);
    --ai-blue-700: hsl(215, 96%, 32%);
    --ai-blue-600: hsl(214, 95%, 36%);
    --ai-blue-500: hsl(212, 92%, 43%);
    --ai-blue-400: hsl(210, 83%, 53%);
    --ai-blue-300: hsl(208, 88%, 62%);
    --ai-blue-200: hsl(206, 93%, 73%);
    --ai-blue-100: hsl(204, 100%, 86%);
    --ai-blue-50: hsl(202, 100%, 95%);
  
    --ai-secondary-50: #fff9e0;
    --ai-secondary-100: #feefb3;
    --ai-secondary-200: #fee580;
    --ai-secondary-300: #feda4d;
    --ai-secondary-400: #fdd226;
    --ai-secondary-500: #fdca00;
    --ai-secondary-600: #fdc500;
    --ai-secondary-700: #fcbd00;
    --ai-secondary-800: #fcb700;
    --ai-secondary-900: #fcab00;

    --ai-gold-50: #faf5e7;
    --ai-gold-100: #f2e7c3;
    --ai-gold-200: #ead79b;
    --ai-gold-300: #e1c773;
    --ai-gold-400: #dabb55;
    --ai-gold-500: #d4af37;
    --ai-gold-600: #cfa831;
    --ai-gold-700: #c99f2a;
    --ai-gold-800: #c39623;
    --ai-gold-900: #b98616;
    --ai-gold-A100: #fff8eb;
    --ai-gold-A200: #ffe7b8;
    --ai-gold-A400: #ffd685;
    --ai-gold-A700: #ffcd6c;
    
    /* --ai-primary-50: #f0e9ff;
    --ai-primary-100: #dbc7ff;
    --ai-primary-200: #c3a2ff;
    --ai-primary-300: #aa7dff;
    --ai-primary-400: #9861ff;
    --ai-primary-500: #8645ff;
    --ai-primary-600: #7e3eff;
    --ai-primary-700: #7336ff;
    --ai-primary-800: #692eff;
    --ai-primary-900: #561fff; */
    
    --ai-primary-50: #f1e9ff;
    --ai-primary-100: #dbc7ff;
    --ai-primary-200: #c3a2ff;
    --ai-primary-300: #ab7dff;
    --ai-primary-400: #9961ff;
    --ai-primary-500: #8745ff;
    --ai-primary-600: #7f3eff;
    --ai-primary-700: #7436ff;
    --ai-primary-800: #6a2eff;
    --ai-primary-900: #571fff;
    --ai-primary-A100: #ffffff;
    --ai-primary-A200: #ffffff;
    --ai-primary-A400: #d9ceff;
    --ai-primary-A700: #c6b5ff;

    --ai-analagous-pink-50: #fce9ff;
    --ai-analagous-pink-100: #f7c7ff;
    --ai-analagous-pink-200: #f2a2ff;
    --ai-analagous-pink-300: #ec7dff;
    --ai-analagous-pink-400: #e861ff;
    --ai-analagous-pink-500: #e445ff;
    --ai-analagous-pink-600: #e13eff;
    --ai-analagous-pink-700: #dd36ff;
    --ai-analagous-pink-800: #d92eff;
    --ai-analagous-pink-900: #d11fff;
    --ai-analagous-pink-A100: #ffffff;
    --ai-analagous-pink-A200: #ffffff;
    --ai-analagous-pink-A400: #f4ceff;
    --ai-analagous-pink-A700: #efb5ff;

    --ai-analagous-rose-50: #ffe9f7;
    --ai-analagous-rose-100: #ffc7eb;
    --ai-analagous-rose-200: #ffa2de;
    --ai-analagous-rose-300: #ff7dd1;
    --ai-analagous-rose-400: #ff61c7;
    --ai-analagous-rose-500: #ff45bd;
    --ai-analagous-rose-600: #ff3eb7;
    --ai-analagous-rose-700: #ff36ae;
    --ai-analagous-rose-800: #ff2ea6;
    --ai-analagous-rose-900: #ff1f98;
    --ai-analagous-rose-A100: #ffffff;
    --ai-analagous-rose-A200: #ffffff;
    --ai-analagous-rose-A400: #ffcee7;
    --ai-analagous-rose-A700: #ffb5db;

    --ai-split-complementary-yellow-50: #fffce9;
    --ai-split-complementary-yellow-100: #fff7c7;
    --ai-split-complementary-yellow-200: #fff2a2;
    --ai-split-complementary-yellow-300: #ffec7d;
    --ai-split-complementary-yellow-400: #ffe861;
    --ai-split-complementary-yellow-500: #ffe445;
    --ai-split-complementary-yellow-600: #ffe13e;
    --ai-split-complementary-yellow-700: #ffdd36;
    --ai-split-complementary-yellow-800: #ffd92e;
    --ai-split-complementary-yellow-900: #ffd11f;
    --ai-split-complementary-yellow-A100: #ffffff;
    --ai-split-complementary-yellow-A200: #ffffff;
    --ai-split-complementary-yellow-A400: #fff4ce;
    --ai-split-complementary-yellow-A700: #ffefb5;

    --ai-split-complementary-green-50: #ecffe9;
    --ai-split-complementary-green-100: #cfffc7;
    --ai-split-complementary-green-200: #b0ffa2;
    --ai-split-complementary-green-300: #90ff7d;
    --ai-split-complementary-green-400: #78ff61;
    --ai-split-complementary-green-500: #60ff45;
    --ai-split-complementary-green-600: #58ff3e;
    --ai-split-complementary-green-700: #4eff36;
    --ai-split-complementary-green-800: #44ff2e;
    --ai-split-complementary-green-900: #33ff1f;
    --ai-split-complementary-green-A100: #ffffff;
    --ai-split-complementary-green-A200: #ffffff;
    --ai-split-complementary-green-A400: #d2ffce;
    --ai-split-complementary-green-A700: #bbffb5;

    --ai-triad-orange-50: #fff1e9;
    --ai-triad-orange-100: #ffdbc7;
    --ai-triad-orange-200: #ffc3a2;
    --ai-triad-orange-300: #ffab7d;
    --ai-triad-orange-400: #ff9961;
    --ai-triad-orange-500: #ff8745;
    --ai-triad-orange-600: #ff7f3e;
    --ai-triad-orange-700: #ff7436;
    --ai-triad-orange-800: #ff6a2e;
    --ai-triad-orange-900: #ff571f;
    --ai-triad-orange-A100: #ffffff;
    --ai-triad-orange-A200: #ffffff;
    --ai-triad-orange-A400: #ffd9ce;
    --ai-triad-orange-A700: #ffc6b5;

    --ai-triad-green-50: #e9fff1;
    --ai-triad-green-100: #c7ffdb;
    --ai-triad-green-200: #a2ffc3;
    --ai-triad-green-300: #7dffab;
    --ai-triad-green-400: #61ff99;
    --ai-triad-green-500: #45ff87;
    --ai-triad-green-600: #3eff7f;
    --ai-triad-green-700: #36ff74;
    --ai-triad-green-800: #2eff6a;
    --ai-triad-green-900: #1fff57;
    --ai-triad-green-A100: #ffffff;
    --ai-triad-green-A200: #ffffff;
    --ai-triad-green-A400: #ceffd9;
    --ai-triad-green-A700: #b5ffc6;

    --ai-square-turquoise-50: #e9fffc;
    --ai-square-turquoise-100: #c7fff7;
    --ai-square-turquoise-200: #a2fff2;
    --ai-square-turquoise-300: #7dffec;
    --ai-square-turquoise-400: #61ffe8;
    --ai-square-turquoise-500: #45ffe4;
    --ai-square-turquoise-600: #3effe1;
    --ai-square-turquoise-700: #36ffdd;
    --ai-square-turquoise-800: #2effd9;
    --ai-square-turquoise-900: #1fffd1;
    --ai-square-turquoise-A100: #ffffff;
    --ai-square-turquoise-A200: #ffffff;
    --ai-square-turquoise-A400: #cefff4;
    --ai-square-turquoise-A700: #b5ffef;

    --ai-gray-50: #F6FDFF;
    --ai-gray-100: #D9E0ED;
    --ai-gray-200: #BDC4D1;
    --ai-gray-300: #A2A9B5;
    --ai-gray-400: #888F9A;
    --ai-gray-500: #6F7580;
    --ai-gray-600: #565C67;
    --ai-gray-700: #3F454F;
    --ai-gray-800: #292F38;
    --ai-gray-900: #141A23;

    --ai-complementary-50: #f7ffe9;
    --ai-complementary-100: #ebffc7;
    --ai-complementary-200: #deffa2;
    --ai-complementary-300: #d1ff7d;
    --ai-complementary-400: #c7ff61;
    --ai-complementary-500: #bdff45;
    --ai-complementary-600: #b7ff3e;
    --ai-complementary-700: #aeff36;
    --ai-complementary-800: #a6ff2e;
    --ai-complementary-900: #98ff1f;
    --ai-complementary-A100: #ffffff;
    --ai-complementary-A200: #ffffff;
    --ai-complementary-A400: #e7ffce;
    --ai-complementary-A700: #dbffb5;
            
}