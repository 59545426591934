@font-face {
    font-family: 'PP Mori';
    src: url('../fonts/PPMori/PPMori-Extralight.woff2');
    src: local('Extralight'),
        url('../fonts/PPMori/PPMori-Extralight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'PP Mori';
    src: url('../fonts/PPMori/PPMori-Light.woff2');
    src: local('PPMoriLight'),
        url('../fonts/PPMori/PPMori-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PP Mori';
    src: url('../fonts/PPMori/PPMori-Regular.woff2');
    src: local('PPMoriRegular'),
        url('../fonts/PPMori/PPMori-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PP Mori';
    src: url('../fonts/PPMori/PPMori-Medium.woff2');
    src: local('PPMoriMedium'),
        url('../fonts/PPMori/PPMori-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PP Mori';
    src: url('../fonts/PPMori/PPMori-SemiBold.woff2');
    src: local('PPMoriSemiBold'),
        url('../fonts/PPMori/PPMori-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PP Mori';
    src: url('../fonts/PPMori/PPMori-Bold.woff2');
    src: local('PPMoriBold'),
        url('../fonts/PPMori/PPMori-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}





@font-face {
    font-family: 'PP Neue Montreal';
    src: url('../fonts/PPNeueMontreal/PPNeueMontreal-Thin.woff2');
    src: local('PPNeueMontrealThin'),
        url('../fonts/PPNeueMontreal/PPNeueMontreal-Thin.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('../fonts/PPNeueMontreal/PPNeueMontreal-Light.woff2');
    src: local('PPNeueMontrealLight'),
        url('../fonts/PPNeueMontreal/PPNeueMontreal-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('../fonts/PPNeueMontreal/PPNeueMontreal-Regular.woff2');
    src: local('PPNeueMontrealRegular'),
        url('../fonts/PPNeueMontreal/PPNeueMontreal-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('../fonts/PPNeueMontreal/PPNeueMontreal-Medium.woff2');
    src: local('PPNeueMontrealMedium'),
        url('../fonts/PPNeueMontreal/PPNeueMontreal-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('../fonts/PPNeueMontreal/PPNeueMontreal-Semibold.woff2');
    src: local('PPNeueMontrealSemibold'),
        url('../fonts/PPNeueMontreal/PPNeueMontreal-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('../fonts/PPNeueMontreal/PPNeueMontreal-Bold.woff2');
    src: local('PPNeueMontrealBold'),
        url('../fonts/PPNeueMontreal/PPNeueMontreal-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}