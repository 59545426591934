.post {
    @apply pt-0;
    h2 {
        @apply text-3xl lg:text-4xl pt-8 text-ai-gray-900;
    }
    h3 {
        @apply text-2xl lg:text-3xl pt-8 text-ai-gray-900;
    }
    img {
        @apply pt-0;
    }
    figure {
        @apply my-4;
    }
    .content {
        ul {
            @apply list-disc pl-6 py-4;
            li {

            }
        }
        a {
            @apply font-medium border-b border-ai-gray-700 border-dotted hover:text-ai-analagous-rose-500 transition duration-300;
        }
    }
}