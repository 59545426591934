.step {
    .mark {
        @apply relative top-8 before:text-ai-analagous-rose-500 before:z-[1001] before:relative before:bg-white before:rounded-full before:w-16 before:h-16 before:flex before:transition-all before:duration-300;
        &.line {
            @apply bg-gradient-line;
        }
        span {
            @apply absolute z-[1002] left-0 top-0 w-16 h-16 flex justify-center items-center text-ai-analagous-rose-500 font-semibold text-3xl;
        }
        &.step-animation {
            span {
                @apply text-white;
            }
            &:before {
                @apply bg-gradient-analagous-rose-triad-orange transition-all duration-300;
            }
        }
    }
    .content {
        @apply bg-white/30 rounded-2xl p-8 my-4;
    }
}   