@property --angle {
    syntax: '<angle>';
    inherits: false;
    initial-value: -216deg;
}

@property --percentage {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 200%;
}

html {
    scroll-behavior: smooth;
}

body {
    @apply bg-ai_dark_2 m-0 antialiased text-xl text-white leading-6;
}

h1, h2 {
    @apply text-6xl lg:text-8xl font-semibold leading-none text-transparent bg-clip-text bg-gradient-full;
}

h3 {
    @apply text-3xl lg:text-5xl font-semibold;
}

h4 {
    @apply text-3xl font-medium pt-8;
}

h5 {
    @apply text-2xl font-semibold pt-8 pb-4 leading-7;
}

p {
    @apply text-xl pt-8;
}

header {
    
}

#root {
    button:not(.swiper-navigate,.dropdown-button) {
        @apply  bg-gradient-full-extended rounded-full p-0.5 mt-8 transition-[background] duration-300 bg-pos-0 hover:bg-pos-100 bg-size-200;
        div {
            @apply flex w-full h-full rounded-full bg-ai_dark_2 items-center justify-between flex-row px-8 pt-2 pb-1.5;
        }
        &.dark {
            @apply bg-none border-[3px] border-ai-gray-900 text-ai-grey-900 hover:border-ai-purple-500 hover:text-ai-purple-500 transition-[border] duration-300;
            div {
                @apply bg-transparent;
            }
        }
    }
}

section {
    @apply py-16 2xl:py-32 bg-ai-gray-900 relative;
    &>div {
        @apply container mx-auto flex flex-col px-6 lg:px-16 xl:px-0;
    }
}

.reveal {
    @apply transition-all duration-700 opacity-0 ;
    &.top { @apply translate-y-12; }
    &.bottom { @apply -translate-y-12; }
    &.left { @apply translate-x-12; }
    &.left { @apply -translate-x-12; }
    &.active { @apply translate-y-0 translate-x-0 opacity-100; }
}

.swiper-controls {
    @apply py-8 w-full flex place-content-end font-symbol text-ai_dark container mx-auto;
    .swiper-navigate {
        @apply transition-all duration-300 w-10 h-10 bg-gradient-primary-analagous-pink rounded-full ml-4 flex items-center justify-center transition-[background] duration-300 bg-size-200 bg-pos-0 hover:bg-pos-100 disabled:opacity-20;
        div {
            @apply bg-ai_dark_2 rounded-full w-9 h-9 text-white flex items-center justify-center;
        }
        &.next {
            
        }
    }
    &.light {
        .swiper-navigate {
            @apply bg-gradient-analagous-rose-triad-orange;
            div {
                @apply bg-gray-100 transition-all duration-300 text-ai-gray-600 hover:text-ai-analagous-rose-500;
            }
        }
    }
}

.list {
    @apply p-4 pl-0;
    li {
        //@apply before:content-['\2605'] before:mr-2 pt-2;
        @apply mt-4 pl-4 border-l border-ai-gray-900;
    }
}

.radio-button {
    
}

.post-excerpt {
    @apply flex flex-col px-8 relative h-1/2;
    p {
        @apply pt-0;
    }
}

.standard-text {
    p, h3, h5 {
        @apply py-0;
    }
    h3 {
        @apply pb-6 pt-8;
    }
    h5 {
        @apply pb-4;
    }
    p {
        @apply pb-4;
    }
    ul {
        @apply p-0 m-0 text-xl;
        li {
            @apply border-none p-0 ml-8 mt-2 mb-4 list-disc;
        }
    }
    ol {
        @apply p-0 m-0 text-xl;
        li {
            @apply border-none p-0 mt-2;
        }
    }
}

.mailto {
    @apply font-semibold transition duration-150 hover:text-ai-primary-500 hover:border-ai-primary-500 border-b border-ai-gray-700 border-dotted;
}

.linkto {
    @apply transition duration-150 font-semibold hover:text-ai-primary-500 hover:border-ai-primary-500 border-b border-ai-gray-700 border-dotted;
}

.table-of-contents {
    li a {
        @apply border-b border-dotted border-b-ai-gray-700;
    }
}

#root {
    .form {
        @apply fixed flex flex-col top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[500px] md:max-w-[1128px] z-[99999] bg-ai-gray-900 text-ai-gray-200 rounded-3xl transition-all opacity-0 mt-16 duration-500;
        &.active {
            @apply opacity-100 mt-0;
        }
        .close {
            @apply font-symbol cursor-pointer w-8 h-8 self-end text-ai-gray-200 flex items-center justify-center bg-ai-gray-800 rounded-full;
        }
        input {
            @apply appearance-none block w-full bg-ai-gray-800 text-ai-gray-200 border border-ai-gray-600 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-ai-gray-700;
        }
        button {
            @apply  bg-gradient-full-extended rounded-full p-0.5 mt-8 transition-[background] duration-300 bg-pos-0 hover:bg-pos-100 bg-size-200;
            div {
                @apply flex w-full h-full rounded-full bg-ai-gray-900 items-center justify-between flex-row px-8 pt-2 pb-1.5;
            }
        }
    }

    .form-page {
        .fields {
            @apply grid grid-cols-1 xl:grid-cols-2 gap-8 text-ai-gray-900;
        }
        .close {
            @apply font-symbol cursor-pointer w-8 h-8 self-end text-ai-gray-200 flex items-center justify-center bg-ai-gray-800 rounded-full;
        }
        input:not([type=checkbox]) {
            @apply appearance-none block w-full bg-white/15 text-ai-gray-900 border border-white/40 rounded-t py-3 px-4 leading-tight focus:outline-none focus:bg-white/30;
            &.error {
                @apply border-ai-triad-orange-900;
            }
        }
        input[type=checkbox] {
            @apply flex self-start justify-center relative appearance-none h-12 after:absolute after:transform after:-translate-y-1/2 after:top-1/2 after:ml-6 after:w-6 after:h-6 after:bg-transparent after:cursor-pointer after:border after:border-black/20 after:flex after:items-center after:justify-center;
            &:checked {
                @apply after:content-["check"] after:font-symbol;
            }
        }
        textarea {
            @apply w-full h-full bg-white/15 rounded-t border border-white/40 appearance-none outline-none;
        }
        /* button:not(.dropdown-button) {
            @apply bg-gradient-full-extended rounded-full p-0.5 mt-8 transition-[background] duration-300 bg-pos-0 hover:bg-pos-100 bg-size-200;
            div {
                @apply flex w-full h-full rounded-full bg-ai-gray-900 items-center justify-between flex-row px-8 pt-2 pb-1.5;
            }
        }
        button[type=submit] {
            @apply bg-none border-ai-gray-900 border-2 rounded-full flex w-auto px-8 py-3 text-ai-gray-900 font-semibold;
            &[disabled] {
                @apply border-ai-gray-200 text-ai-gray-200;
            }
        } */
        p.error {
            @apply text-base text-ai-triad-orange-900 pt-1 leading-none;
        }
        .error-notification {
            @apply bg-white/15 px-4 py-2 border border-t-0 border-ai-triad-orange-900 rounded-b z-[15] w-full;
        }
        button:not(.dropdown-button) {
            @apply cursor-pointer bg-none border-[3px] border-ai-gray-900 text-ai-grey-900 hover:border-ai-purple-500 hover:text-ai-purple-500 transition-[border] duration-300 disabled:cursor-not-allowed hover:disabled:text-ai-gray-900 hover:disabled:border-ai-gray-900 disabled:opacity-20;
            div {
                @apply bg-transparent;
            }
        }
        &.dark {
            .fields {
                @apply grid grid-cols-1 xl:grid-cols-2 gap-8 text-white/75;
                input:not([type=checkbox]) {
                    @apply text-white/85;
                }
                input[type=checkbox] {
                    @apply after:border-white/20;
                }
            }
            button[type=submit] {
                /* @apply border-white/30 text-white/30;
                &[disabled] {
                    @apply border-ai-gray-200 text-ai-gray-200;
                } */
                
                @apply border-none cursor-pointer appearance-none text-white font-medium bg-gradient-full-extended rounded-full p-0.5 mt-8 transition-[background] duration-300 bg-pos-0 hover:bg-pos-100 bg-size-200 disabled:cursor-not-allowed hover:disabled:text-white hover:disabled:bg-pos-0 ;
                div {
                    @apply flex w-full h-full rounded-full bg-ai_dark_2 items-center justify-between flex-row px-8 pt-2 pb-1.5;
                }
                
            }
        }
    }
}

:root {
    scrollbar-color: #efefef white;
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 2px;
}

*::-webkit-scrollbar-track {
    background: white;
    border-right: 1px solid var(--ai-gray-900);
}

*::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 0px;
    border: 3px solid var(--ai-gray-900);
}

.shimmer {
    @apply w-[700px] opacity-0 text-white/10 text-3xl sm:text-5xl font-semibold translate-x-[66px] sm:translate-x-[170px] text-center bg-clip-text bg-gradient-shimmer bg-size-125 animate-shimmer bg-no-repeat bg-pos-0 bg-ai-gray-700;
}