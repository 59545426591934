.dropdown-button {
    @apply after:content-[attr(after)] relative select-none z-[12] h-auto w-full bg-white/15 cursor-pointer text-ai-gray-900 border border-white/40 rounded py-3 px-4 flex items-center after:font-symbol after:absolute after:right-2;
    &.error {
        @apply border-red-500;
    }
}

.dropdown-box {
    @apply relative z-[17] flex flex-col top-0 h-0 bg-white/100 w-auto text-ai-gray-900 p-0 transition duration-150;
    li {
        @apply px-8 pb-4 bg-white/100 mb-0 cursor-pointer;
        &:first-child {
            @apply pt-4;
        }
    }
}