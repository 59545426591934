@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@import './assets/scss/global';
@import './assets/scss/fonts';
@import './assets/scss/colors';
@import './assets/scss/components';
@import './assets/scss/pages';
